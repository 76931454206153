import React from "react";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import FooterContact from "../FooterContact";
import { useTranslation } from "react-i18next";

function FormRegis() {
  const { t, i18n } = useTranslation();
  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };
  return (
    <div>
      <div className="w-full xl:h-[200px] h-[120px] bg-indigo-600">
        <div className="container grid mx-auto h-full">
          <div className="mx-auto my-auto Zince xl:text-[50px] text-[22px]">
            {t("career")}
          </div>
        </div>
        <div className="p-5 text-center">{t("career2")}</div>
        <div className="xl:w-[1200px] border-2 m-5 mx-auto">
          <div className="w-[1200px] h-[50px] bg-red-600 flex justify-between">
            <div className="p-3 flex bg-red-600 text-white ">
              <FaEdit className="w-5 h-7" />
              <p className="ml-1 mt-[0.5px]">{t("apply")}</p>
            </div>
          </div>
          <div className="m-5">
            <div className="w-[1000px]">
              <div className="p-1 leading-7">
                <h2 className="p-2 mt-2.5">{t("apply1")}</h2>
                <ul className="border-b-2 p-1">
                  <li className="list-disc ml-5 p-1">{t("apply2")}</li>
                  <li className="list-disc ml-5 p-1">{t("apply3")}</li>
                  <li className="list-disc ml-5 p-1">{t("apply4")}</li>
                  <li className="list-disc ml-5 p-1">{t("apply5")}</li>
                </ul>

                <h2 className="p-2 mt-2.5">{t("apply6")}</h2>
                <ul className="p-1 border-b-2">
                  <li className="list-disc ml-5 p-1">{t("apply7")}</li>
                </ul>
                <h2 className="p-2 mt-2.5">{t("apply8")}</h2>
                <ul className="p-1">
                  <li className="list-disc ml-5 p-1">Email : hr@baethai.com</li>
                </ul>
                <h2 className="p-2 mt-2.5 text-center font-bold">
                  {t("de27")}
                </h2>
              </div>
            </div>
          </div>
          <Link to="/WorkWithUs">
            <div className="">
              <button className="bg-red-500 my-8 mx-[500px] p-2 rounded-full">
                {t("de28")}
              </button>
            </div>
          </Link>
        </div>
        <FooterContact />
      </div>
    </div>
  );
}

export default FormRegis;
