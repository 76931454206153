import React from "react";
import { useTranslation } from "react-i18next";
function Customer() {
  const { t, i18n } = useTranslation();
  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };
  return (
    <div className="container mx-auto p-5">
      <div className="p-3 Font1 font-bold xl:text-[30px] xl:text-center text-[25px] text-center">
        {t("customer")}
      </div>
      <div className="grid xl:grid-cols-3 container mx-auto">
        <div>
          <img
            src="https://img-baewebsite.s3.ap-southeast-1.amazonaws.com/customer/ct0.jpg"
            alt=""
            className="w-[300px] mx-auto my-8 hover:scale-110 ease-in duration-500"
          />
        </div>
        <div>
          <img
            src="https://img-baewebsite.s3.ap-southeast-1.amazonaws.com/customer/ct1.jpg"
            alt=""
            className="w-[300px] mx-auto my-8 hover:scale-110 ease-in duration-500"
          />
        </div>
        <div>
          <img
            src="https://img-baewebsite.s3.ap-southeast-1.amazonaws.com/customer/ct2.jpg"
            alt=""
            className="w-[300px] mx-auto my-8 hover:scale-110 ease-in duration-500"
          />
        </div>
        <div>
          <img
            src="https://img-baewebsite.s3.ap-southeast-1.amazonaws.com/customer/ct3.jpg"
            alt=""
            className="w-[300px] mx-auto my-8 hover:scale-110 ease-in duration-500"
          />
        </div>
        <div>
          <img
            src="https://img-baewebsite.s3.ap-southeast-1.amazonaws.com/customer/ct4.jpg"
            alt=""
            className="w-[300px] mx-auto my-8 hover:scale-110 ease-in duration-500"
          />
        </div>
        <div>
          <img
            src="https://img-baewebsite.s3.ap-southeast-1.amazonaws.com/customer/ct5.jpg"
            alt=""
            className="w-[300px] mx-auto my-8 hover:scale-110 ease-in duration-500"
          />
        </div>
      </div>
    </div>
  );
}

export default Customer;
