import React from "react";
import { useTranslation } from "react-i18next";
import "./text.css";
import { FaBuilding, FaEdit } from "react-icons/fa";
import FooterContact from "./FooterContact";
import { Link } from "react-router-dom";
function WorkWithUs() {
  const { t, i18n } = useTranslation();
  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };
  return (
    <div className="">
      <div className="w-full xl:h-[200px] h-[120px] bg-indigo-600">
        <div className="container grid mx-auto h-full">
          <div className="mx-auto my-auto Zince xl:text-[50px] text-[22px]">
            {t("career")}
          </div>
        </div>
        <div className="xl:p-5 p-2 mt-3 text-center">{t("career2")}</div>
        <div className="xl:w-[1200px] h-[650px]  border-2 m-5 mx-auto ">
          <div className="w-[1200px] h-[50px] bg-indigo-600 text-white flex justify-between">
            <div className="p-3 flex">
              <FaBuilding className="w-5 h-6" />
              <p className="ml-1">{t("positions")}</p>
            </div>
            <Link to="/FormRegister">
              <div className="p-3 flex bg-red-600 text-white ">
                <FaEdit className="w-5 h-7" />
                <p className="ml-1 mt-[0.5px]">{t("apply")}</p>
              </div>
            </Link>
          </div>
          <div className="m-10 xl:text-[1rem] text-[0.25rem] font-bold">
            {/* <div className="xl:w-[1000px] w-[300px] xl:h-[100px] text-center xl:mx-auto xl:flex xl:justify-between border-b-2">
              <div className="ml-10">
                <h2 className="p-2 mt-2.5">{t("positions1")}</h2>
                <p className="p-2">{t("Date1")}</p>
              </div>
              <Link to="/detail/1">
                <div>
                  <button className="bg-red-500 xl:my-8 p-2 m-2 rounded-full">
                    {t("Detail")}
                  </button>
                </div>
              </Link>
            </div> */}
            {/* <div className="xl:w-[1000px] w-[300px] xl:h-[100px] text-center xl:mx-auto xl:flex xl:justify-between border-b-2">
              <div className="ml-10">
                <h2 className="p-2  mt-2.5">Design Engineer</h2>
                <p className="p-2">{t("Date1")}</p>
              </div>
              <Link to="/detail/2">
                <div>
                  <button className="bg-red-500 xl:my-8 p-2 m-2 rounded-full">
                    {t("Detail")}
                  </button>
                </div>
              </Link>
            </div> */}
            {/* <div className="xl:w-[1000px] w-[300px] xl:h-[100px] text-center xl:mx-auto xl:flex xl:justify-between border-b-2">
              <div className="ml-10">
                <h2 className="p-2 mt-2.5">{t("positions3")}</h2>
                <p className="p-2">{t("Date1")}</p>
              </div>
              <Link to="/detail/3">
                <div>
                  <button className="bg-red-500 xl:my-8 p-2 m-2 rounded-full">
                    {t("Detail")}
                  </button>
                </div>
              </Link>
            </div> */}
            <div className="xl:w-[1000px] w-[300px] xl:h-[100px] text-center xl:mx-auto xl:flex xl:justify-between border-b-2">
              <div className="ml-10">
                <h2 className="p-2 mt-2.5">{t("de0.1")}</h2>
                <p className="p-2">{t("Date1")}</p>
              </div>
              <Link to="/detail/4">
                <div>
                  <button className="bg-red-500 xl:my-8 p-2 m-2 rounded-full">
                    {t("Detail")}
                  </button>
                </div>
              </Link>
            </div>
            {/* <div className="xl:w-[1000px] w-[300px] xl:h-[100px] text-center xl:mx-auto xl:flex xl:justify-between border-b-2">
              <div className="ml-10">
                <h2 className="p-2 mt-2.5">{t("de0.11")}</h2>
                <p className="p-2">{t("Date1")}</p>
              </div>
              <Link to="/detail/5">
                <div>
                  <button className="bg-red-500 xl:my-8 p-2 m-2 rounded-full">
                    {t("Detail")}
                  </button>
                </div>
              </Link>
            </div> */}
          </div>
        </div>
        <FooterContact />
      </div>
    </div>
  );
}

export default WorkWithUs;
