import Router from './routes/Router';

function App() {
	return (
		<>
			<Router />
		</>
	);
}

export default App;
