import React from "react";
import { Route, Routes } from "react-router-dom";
import PostPage from "../pages/PostPage";
import ZinceProduct from "../pages/zincePage/ZinceProduct";
import ProductPage from "../pages/productPage/ProductPage";
import EdpPage from "../pages/edpComponent/EdpPage";
import Navbar2 from "../layoutComponent/Navbar2";
import ChromiumPage from "../pages/chromiumpage/ChromiumPage";
import Stamping from "../pages/stampimg/Stamping";
import History from "../pages/history/History";
import Contact from "../contact/Contact";
import AllProductPage from "../pages/allProduct/AllProductPage";
import Tooling from "../pages/tooling/Tooling";
import QualityControlPage from "../pages/qc/QualityControlPage";
import WorkWithUs from "../contact/WorkWithUs";
import Detail1 from "../contact/detailJob/Detail1";
import Detail2 from "../contact/detailJob/Detail2";
import Detail3 from "../contact/detailJob/Detail3";
import FormRegis from "../contact/detailJob/FormRegis";
import Detail4 from "../contact/detailJob/Detail4";
import Detail5 from "../contact/detailJob/Detail5";
function Router() {
  return (
    <>
      <Navbar2 />
      <Routes>
        <>
          <Route path="/" element={<PostPage />} />
          <Route path="/MyService" element={<AllProductPage />} />
          <Route path="/PlatingProduct" element={<ProductPage />} />
          <Route path="/Zinc" element={<ZinceProduct />} />
          <Route path="/Edp" element={<EdpPage />} />
          <Route path="/Chromium" element={<ChromiumPage />} />
          <Route path="/Stamping&welding" element={<Stamping />} />
          <Route path="/AboutUs" element={<History />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/WorkWithUs" element={<WorkWithUs />} />
          <Route path="/detail/1" element={<Detail1 />} />
          <Route path="/detail/2" element={<Detail2 />} />
          <Route path="/detail/3" element={<Detail3 />} />
          <Route path="/detail/4" element={<Detail4 />} />
          <Route path="/detail/5" element={<Detail5 />} />
          <Route path="/FormRegister" element={<FormRegis />} />
          <Route path="/Tooling" element={<Tooling />} />
          <Route path="/QualityControl" element={<QualityControlPage />} />
        </>
      </Routes>
    </>
  );
}

export default Router;
