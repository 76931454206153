import React from "react";
import { FaBuilding, FaEdit } from "react-icons/fa";
import FooterContact from "../FooterContact";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Detail4() {
  const { t, i18n } = useTranslation();
  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };
  return (
    <div>
      <div className="w-full xl:h-[200px] h-[120px] bg-indigo-600">
        <div className="container grid mx-auto h-full">
          <div className="mx-auto my-auto Zince xl:text-[50px] text-[22px]">
            {t("career")}
          </div>
        </div>
        <div className="xl:p-5 p-2 mt-3 text-center">{t("career2")}</div>
        <div className="xl:w-[1200px] border-2 m-5 mx-auto">
          <div className="w-[1200px] h-[50px] bg-indigo-600 text-white flex justify-between">
            <div className="p-3 flex">
              <FaBuilding className="w-5 h-7" />
              <p className="ml-1">{t("de0.1")}</p>
            </div>
            <Link to="/FormRegister">
              <div className="p-3 flex bg-red-600 text-white ">
                <FaEdit className="w-5 h-7" />
                <p className="ml-1 mt-[0.5px]">{t("apply")}</p>
              </div>
            </Link>
          </div>
          <div className="xl:m-5 m-2 xl:text-[1rem] text-[0.25rem]">
            <div className="w-[1000px]">
              <div className="p-1 leading-7">
                <h2 className="p-2 mt-2.5">{t("Nature of work")}</h2>
                <ul className="border-b-2 p-1">
                  <li className="list-disc ml-5 p-1">{t("de0.2")}</li>
                  <li className="list-disc ml-5 p-1">{t("de0.3")}</li>
                  <li className="list-disc ml-5 p-1">{t("de0.4")}</li>
                </ul>
                <h2 className="p-2 mt-2.5">{t("de6")}</h2>
                <ul className="border-b-2 p-1">
                  <li className="list-disc ml-5 p-1">{t("de0.55")}</li>
                  <li className="list-disc ml-5 p-1">{t("de0.56")}</li>
                  <li className="list-disc ml-5 p-1">{t("de0.57")}</li>
                  <li className="list-disc ml-5 p-1">{t("de0.58")}</li>
                  <li className="list-disc ml-5 p-1">{t("de0.59")}</li>
                </ul>
                <div className="border-b-2 p-1">
                  <h2 className="p-2 ">{t("de12")}</h2>
                  <h2 className="p-2 ">{t("de13")}</h2>
                  <h2 className="p-2 ">{t("de14")}</h2>
                </div>
                <h2 className="p-2 mt-2.5">{t("de15")}</h2>
                <ul className="p-1 border-b-2">
                  <li className="list-disc ml-5 p-1">{t("de16")}</li>
                  <li className="list-disc ml-5 p-1">{t("de17")}</li>
                  <li className="list-disc ml-5 p-1">{t("de18")}</li>
                  <li className="list-disc ml-5 p-1">{t("de19")}</li>
                  <li className="list-disc ml-5 p-1">{t("de20")}</li>
                  <li className="list-disc ml-5 p-1">{t("de21")}</li>
                  <li className="list-disc ml-5 p-1">{t("de22")}</li>
                </ul>
                <h2 className="p-2 mt-2.5">{t("de23")}</h2>
                <ul className="p-1">
                  <li className="list-disc ml-5 p-1">{t("de24")}</li>
                  <li className="list-disc ml-5 p-1">{t("de25")}</li>
                  <li className="list-disc ml-5 p-1">{t("de26")}</li>
                  <li className="list-disc ml-5 p-1">Email : hr@baethai.com</li>
                </ul>
                <h2 className="p-2 mt-2.5 xl:text-center font-bold ml-5 text-[0.5rem]">
                  {t("de27")}
                </h2>
              </div>
            </div>
          </div>
          <Link to="/WorkWithUs">
            <div className="">
              <button className="bg-red-500 my-8 xl:mx-[500px] ml-[150px] p-2 rounded-full">
                {t("de28")}
              </button>
            </div>
          </Link>
        </div>
        <FooterContact />
      </div>
    </div>
  );
}

export default Detail4;
